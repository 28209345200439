import React, { useState } from 'react';
import { useParams } from "react-router-dom";
import { toast } from 'react-toastify';
import api from '../../services/api';
import './PasswordChange.css';

export default () => {

    const { id, token } = useParams()


    const [credenciais, setCredenciais] = useState(
        {
            token: token,
            password: '',
            userId: id  
        }
    )
    const [repetirCredencial, setRepetirCredencial] = useState(
        {
            password: ''
        }
    )

    const checkPasswordAndSubmit = async () => {

        window.addEventListener('submit', function (e) { e.preventDefault() })
        const btnSend = document.querySelector('#btn_send');
        btnSend.classList.add("loadingBtn");

        const validateFields = document.querySelectorAll('#change-password :invalid')?.length;
        if (validateFields !== 0) {
            btnSend.classList.remove("loadingBtn");
            return false
        }

        if (credenciais.password !== repetirCredencial.password) {
            const formRepeat = document.querySelector('#password_repeat');
            formRepeat.classList.add(':invalid')
            btnSend.classList.remove("loadingBtn");
            toast.warn("As senhas devem ser idênticas");
            return false
        }

        await api.post('customer/reset-password', credenciais)
            .then(response => {
                toast.success("Senha alterada com sucesso");
                setTimeout(() => {
                    window.location.replace('/');
                }, 1000);                
            })
            .catch((reason) => {
                toast.warn(reason?.message);
                btnSend.classList.remove("loadingBtn");
            });


    }


    return (

        <div className="container-login-page">
            <div className="frm-main-container">
                <div className="inner">

                    {/* Login */}

                    <span id='email-auth-title'>Nova Senha</span>
                    <span id='email-auth-span'>Insira sua nova senha</span>

                    <form action="" className="frm-signin" id="change-password">

                        <div className="frm-group" id="Pass">
                            <label htmlFor="password">Senha</label>
                            <input onChange={(e) => {
                                setCredenciais({ ...credenciais, password: e.target.value });
                            }}
                                type="password"
                                className="form-control form-control-lg"
                                id='set_password'
                                placeholder="Senha"
                                pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                                title="Sua senha deve conter no mínimo 8 caracteres e pelo menos uma letra maiúscula, uma minúscula e um número."
                                size="36"
                                required
                                autoComplete="off"
                            />
                            <span type="button" className="btn-show-pass" id='logIn_pass' onClick={(event) => {
                                const passInput = document.getElementById('set_password')
                                const passEye = document.querySelector('#Pass .fa')
                                if (passInput.type === 'password') {
                                    passEye.classList.remove('fa-eye-slash')
                                    passInput.type = 'text'
                                }
                                else {
                                    passEye.classList.add('fa-eye-slash')
                                    passInput.type = 'password'
                                }
                            }}>
                                <i id="eyeIcon" className="fa fa-eye fa-eye-slash"></i>
                            </span>

                        </div>
                        <div className="frm-group" id="repeatPass">
                            <label htmlFor="password">Repita a Senha</label>
                            <input onChange={(e) => {
                                setRepetirCredencial({ ...repetirCredencial, password: e.target.value });
                            }}
                                type="password"
                                className="form-control form-control-lg"
                                id='password_repeat'
                                placeholder="Repita a Senha"
                                pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                                title="Sua senha deve conter no mínimo 8 caracteres e pelo menos uma letra maiúscula, uma minúscula e um número."
                                size="36"
                                required
                                autoComplete="off"
                            />
                            <span type="button" className="btn-show-pass" id='logIn_pass' onClick={(event) => {
                                const passInput = document.getElementById('password_repeat')
                                const passEye = document.querySelector('#repeatPass .fa')
                                if (passInput.type === 'password') {
                                    passEye.classList.remove('fa-eye-slash')
                                    passInput.type = 'text'
                                }
                                else {
                                    passEye.classList.add('fa-eye-slash')
                                    passInput.type = 'password'
                                }
                            }}>
                                <i id="eyeIcon" className="fa fa-eye fa-eye-slash"></i>
                            </span>

                        </div>
                        <div className="frm-group chk">

                        </div>
                        <div className="frm-group">
                            <br />
                            <button className="" id="btn_send" type="submit"
                                onClick={() => { checkPasswordAndSubmit() }}
                            >Confirmar</button>
                        </div>
                        <hr />
                        <div className="text-center">
                            <small className="fp-link">Sua senha deve conter no mínimo 8 caracteres e pelo menos uma letra maiúscula, uma minúscula e um número.</small>
                        </div>
                    </form>



                </div>
            </div>
        </div>
    );

}