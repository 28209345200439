import React, { useState, useEffect, useContext, useRef } from "react";
import { CartContext } from "../../Contexts/cart-context";
import { useNavigate } from 'react-router-dom';
import api from '../../services/api';
import './Carrinho.css';

import { CloseBtn } from "../utils/close-btn/CloseBtn";
import { UserContext } from "../../Contexts/user-context";


const Carrinho = React.memo(function Carrinho() {

    const location = useNavigate()

    const { cart, removeCartItem } = useContext(CartContext);
    const { userConstants } = useContext(UserContext)

    const cartRef = useRef([]);
    const [openCart, setOpenCart] = useState(false);

    const handler = (e) => {
        if (!cartRef.current?.contains(e.target)) {
            setOpenCart(false);
        }
    }

    useEffect(() => {
        document.addEventListener('mousedown', handler);
        return () => {
            document.removeEventListener('mousedown', handler)
        }
    })

    const hasCart = cart && cart?.length > 0

    const { PLANO_INTERMEDIARIO, PLANO_MASTER, DESCONTO_INTERMEDIARIO, DESCONTO_MASTER } = userConstants

    const resolveTotalCartItems = () => (
        cart?.map(item => {
            const price = item.amount / 100;

            return PLANO_INTERMEDIARIO ?
                (price - price * DESCONTO_INTERMEDIARIO) :
                PLANO_MASTER ?
                    (price - price * DESCONTO_MASTER) :
                    price;
        })
            .reduce((accumulator, item) => accumulator + parseFloat(item), 0)
    ).toFixed(2).replace(".", ",")

    return (

        <div className="header--cart" id="header--cart" ref={cartRef} >
            <span onClick={() => { setOpenCart(!openCart) }}>
                <i className="fa fa-shopping-cart" aria-hidden="true"></i>
                {hasCart && <span className="header--cartCount" >{cart?.length}</span>}
            </span>


            {openCart && <>
                <div className="header--cartList activeCartList" id="header--cartList">
                    <CloseBtn onClick={() => { setOpenCart(!openCart) }} />
                    {!hasCart && <div className="cartEmpty" id="cartEmpty">
                        <h2>Seu carrinho está vazio</h2>
                        <span>Continue navegando e escolha à avontade</span>
                    </div>}

                    {hasCart && <h2>Seu Carrinho:</h2>}
                    <div className="header--cartIn">
                        {hasCart && <div className="shopping-cart-items">

                            {hasCart && cart?.map((cartitem) => (
                                <div key={cartitem.productId}>
                                    <div className="clearfix" id={cartitem.productId} >
                                        <div className="items-flex-intern">
                                            <img className="" src={cartitem.thumb} />
                                            <div className="items-inter">
                                                <span className="item-name">{cartitem.description}</span>
                                                <span className="item-price">
                                                    {"Preço: R$ "} {
                                                        (PLANO_INTERMEDIARIO ? ((cartitem.amount / 100 - cartitem.amount / 100 * DESCONTO_INTERMEDIARIO)).toFixed(2).replace(".", ",") :
                                                            (PLANO_MASTER ? ((cartitem.amount / 100 - cartitem.amount / 100 * DESCONTO_MASTER)).toFixed(2).replace(".", ",") :
                                                                (cartitem.amount / 100).toFixed(2).replace(".", ","))
                                                        )
                                                    }
                                                </span>
                                            </div>
                                            {/*<span className="item-quantity">Quantidade: {cartitem.quantity}</span>*/}

                                            <div className="product-off fa fa-close" data-id={cartitem.productId}
                                                onClick={() => removeCartItem(cartitem.productId)}>
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                </div>
                            ))}

                        </div>}
                    </div>
                    <div className="items-flex-bottom">
                        {hasCart && (
                            <div>
                                Total: R$ {' '}
                                {resolveTotalCartItems()}
                            </div>
                        )}

                        {hasCart && <div>
                            <button className="btn btn-primary button-guild" id="finalizarCompra" onClick={() => {
                                location('/checkout');
                            }}>
                                💰 Finalizar Compra
                            </button>
                        </div>}
                    </div>
                </div>
            </>
            }
        </div>
    )

})

export default Carrinho;