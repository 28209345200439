import React, { useState, createContext, useEffect } from "react";
import { toast } from 'react-toastify';
import api from '../../src/services/api';

export const RaceContext = createContext();

const RaceProvider = ({ children }) => {

    const [races, setRaces] = useState();
    const [selectedRace, setSelectedRace] = useState();

    const getRaces = async () => {
        try {
            const response = await api.get('/race');
            const res = response.data;

            setRaces(res);    

        } catch (err) {
            toast.warn("Não foi possíel recuperar os monstros da base de dados" + err.message);
        }
    };     

    return (
        <RaceContext.Provider value={{ races, setRaces, selectedRace, getRaces, setSelectedRace }}>
            {children}
        </RaceContext.Provider>
    )

};

export default RaceProvider;