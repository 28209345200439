import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import { CartContext } from "../../Contexts/cart-context";
import api from '../../services/api';

import { CloseBtn } from '../utils/close-btn/CloseBtn';
import compras from '../../assets/carrinho-de-compras.png'
import vip from '../../assets/desconto.png'
import { UserContext } from '../../Contexts/user-context';

const ProductModal = () => {

  const navigate = useNavigate()

  const { cart, getCart, addToCart } = useContext(CartContext);

  const { user, getMyAccount, userConstants } = useContext(UserContext)

  const { PLANO_BASICO, PLANO_INTERMEDIARIO, PLANO_MASTER, DESCONTO_INTERMEDIARIO, DESCONTO_MASTER } = userConstants

  useEffect(() => {
    if (!user) {
      getMyAccount()
    }
  }, [user])

  const [product, setProduct] = useState({});
  const [productFiltered, setProductFiltered] = useState()

  const selectProductListener = () => {
    window.addEventListener('selectProduct', (data) => {
      setProduct(data.detail);
    });
  };

  const productIsInCart = () => {
    const filterCartItem = cart?.filter(element => element.productId === product.id)
    setProductFiltered(filterCartItem)
  }

  useEffect(() => {
    selectProductListener();
  }, []);

  useEffect(() => {
    getCart()
  }, [])

  useEffect(() => {
    productIsInCart()
  }, [product, cart])

  let prodPrice = product.amount / 100

  const price = () => {
    return prodPrice.toFixed(2)
  }

  const discount = () => {
    if (PLANO_INTERMEDIARIO) {
      prodPrice = prodPrice - prodPrice * DESCONTO_INTERMEDIARIO
    }
    if (PLANO_MASTER) {
      prodPrice = prodPrice - prodPrice * DESCONTO_MASTER
    }
    return prodPrice.toFixed(2)
  }

  return (
    <div className="modal fade" id="modal-filme">
      <div className="modal-dialog">
        <div className="modal-content">
          <div
            className="modal-hero"
            style={{ backgroundImage: `url(${product.hero})` }}
          >
            <div className="modal-hero-pos">
              <CloseBtn dataDismiss={"modal"} />
              <img src={product.title_lettering} alt={product.title} className="title-lettering" />
              <div className='modal-hero-infos-plus'>

                {!productFiltered?.length > 0 &&
                  <>
                    {
                      PLANO_BASICO &&
                      <div
                        className='modal--price'
                      >
                        {' R$ '}{price().replace(".", ",")}
                      </div>
                    }
                    {
                      (PLANO_INTERMEDIARIO || PLANO_MASTER) &&
                      <div>
                        <div
                          style={{
                            fontSize: '15px', // ou qualquer outro tamanho desejado
                            textDecoration: 'line-through', // adicionar um risco
                            color: 'gray', // ou qualquer outra cor desejada
                            marginBottom: '0px'
                          }}
                          className='modal--price'>{' R$ '}{price().replace(".", ",")}
                        </div>
                        <div
                          style={{
                            marginTop: '0px', // ou qualquer outra cor desejada
                          }}
                          className='modal--price'>{' R$ '}{discount().replace(".", ",")}
                        </div>
                      </ div>
                    }
                    <div
                      className="featured--watchbutton"
                      onClick={() => addToCart(product)}
                    ><img src={compras} className="modal-hero-infos-icons" />
                      Adicionar
                    </div>
                    {(PLANO_BASICO || PLANO_INTERMEDIARIO) &&
                      <div
                        className="featured--watchbutton"
                        data-dismiss="modal"
                        onClick={() => { navigate('/signature') }}
                        style={{ backgroundColor: '#20eb12fa' }}
                      >
                        <img src={vip} className="modal-hero-infos-icons" />
                        + Descontos
                      </div>
                    }
                  </>
                }
                {productFiltered?.length > 0 &&

                  <div
                    className="featured--watchbutton"
                    data-dismiss="modal"
                    onClick={
                      () => {
                        navigate('/checkout')
                      }
                    }
                  >
                    <img src={compras} style={{ maxWidth: '30px', maxHeight: '30px', margin: '0px', marginRight: '10px' }} />
                    Finalizar Compra
                  </div>
                }

              </div>
            </div>

          </div>
          <div className="modal-infos">
            <div className="container">
              <div className="row product--modal--contents">
                <div className="col-7" >
                  <span className='modal--sinopse'>Sinopse</span>

                  <p className="filme_descricao">{product.description}</p>
                </div>
                <div className="col-5">
                  <p className="filme_elenco">
                    {/* Autor: <span>{" " + product.autors?.join(', ')}</span>
                    <br />
                    Direção de Arte: <span>{product.credits?.join(', ')}</span>
                    <br /> */}
                    Extras: <span>{" " + product.includes?.join(', ')}</span>
                  </p>
                </div>
              </div>


            </div>
          </div>

        </div>
      </div>
    </div>
  );
};

export default ProductModal;
