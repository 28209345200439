import React, { useState, createContext } from "react";
import { toast } from 'react-toastify';
import api from '../../src/services/api';

export const ProductContext = createContext();

const ProductProvider = ({ children }) => {

    const [principal, setPrincipal] = useState();
    const [secoes, setSecoes] = useState([]);
    const [collection, setCollection] = useState([]);
    const [manuals, setManuals] = useState([]);
    const [selected, setSelected] = useState();
    const [selectedProduct, setSelectedProduct] = useState([]);

    const getHome = async () => {
        try {
            const response = await api.get('/product?type=Aventura');
            const res = response.data;
            setSecoes(res.produtos);
        } catch (err) {
            toast.warn("Aventuras não carregaram corretamente" + err.message);
        }
    };

    const getManuals = async () => {
        try {
            const response = await api.get('/product?type=Manual');
            const res = response.data;
            setManuals(res.produtos);
        } catch (err) {
            toast.warn("Manuais não carregaram corretamente" + err.message);
        }
    };

    const getCollections = async () => {
        try {
            const response = await api.get('/product/paid?type=Aventura'); //TODO ver isso
            const res = response.data;

            const results = res?.produtos

            if (results === null || undefined) { return }

            setCollection(results) // pega as Aventuras      
            if (results?.length > 0) {
                const tamanho = results.length; // Pega o tamanho do array
                const aleatorioIndex = Math.floor(Math.random() * tamanho); // Gera um índice aleatório
                setPrincipal(results[aleatorioIndex] || results[0]); // Usa o elemento aleatório ou o primeiro como fallback
            }

        } catch (err) {

            toast.warn("Materiais adquiridos não carregaram corretamente" + err);
        }
    };

    return (
        <ProductContext.Provider value={{ principal, setPrincipal, secoes, setSecoes, collection, setCollection, getHome, getCollections, selected, setSelected, manuals, getManuals }}>
            {children}
        </ProductContext.Provider>
    )

};

export default ProductProvider;