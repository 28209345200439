import React, { useState, createContext, useEffect } from "react";
import { toast } from 'react-toastify';
import api from '../../src/services/api';

export const MonsterContext = createContext();

const MonsterProvider = ({ children }) => {

    const [monsters, setMonsters] = useState();
    const [selectedMonster, setSelectedMonster] = useState();

    const getMonsters = async () => {
        try {
            const response = await api.get('/monster');
            const res = response.data;

            if (res.monsters === null) { return }

            setMonsters(res);    

        } catch (err) {
            toast.warn("Não foi possíel recuperar os monstros da base de dados" + err.message);
        }
    };   

    return (
        <MonsterContext.Provider value={{ monsters, setMonsters, selectedMonster, getMonsters, setSelectedMonster }}>
            {children}
        </MonsterContext.Provider>
    )

};

export default MonsterProvider;