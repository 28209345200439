import React, { useState, useEffect, useCallback, useContext } from "react";
import './promoHeader.css'
import { Link } from "react-router-dom";
import { UserContext } from "../../Contexts/user-context";

const Countdown = () => {
    const [countdown, setCountdown] = useState(getCountdown());

    function getCountdown() {
        const targetDate = new Date('February 10, 2024 23:59:59').getTime();
        const now = new Date().getTime();
        const distance = targetDate - now;

        if (distance < 0) {
            return 'Promoção expirada!';
        }

        const days = Math.floor(distance / (1000 * 60 * 60 * 24));
        const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((distance % (1000 * 60)) / 1000);

        return `${days}d ${hours}h ${minutes}m ${seconds}s`;
    }

    const updateCountdown = useCallback(() => {
        setCountdown(getCountdown());
    }, []);

    useEffect(() => {
        const intervalId = setInterval(updateCountdown, 1000);

        return () => clearInterval(intervalId);
    }, [setCountdown]);

    return (
        <div >
            <p id="countdown">{countdown}</p>
        </div>
    )
}

const PromoHeader = () => {

    const { user } = useContext(UserContext);

    const [open, setOpen] = useState(

        localStorage.getItem('@isOpen') === 'true' ? true :
            localStorage.getItem('@isOpen') === 'false' ? false :
                true
    );
    const [promo, setPromo] = useState(true);
    const [isMobile, setIsMobile] = useState(false);
    const [windowSize, setWindowSize] = useState(null);

    const [bg, setBg] = useState("#4dcefffa"); //"#ffd830fa"

    const handleToggle = () => {
        setOpen(!open)
        localStorage.setItem('@isOpen', String(!open))
    };

    useEffect(() => {
        const handleResize = () => {
            const width = window.innerWidth;
            setWindowSize(width);
            setIsMobile(width <= 768); // Define como móvel se a largura for menor ou igual a 768px
        };

        // Chama handleResize inicialmente e adiciona o ouvinte de evento para redimensionamento
        handleResize();
        window.addEventListener("resize", handleResize);

        // Remove o ouvinte de evento ao desmontar o componente
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    if (!promo) {
        return null
    }

    if (user?.subscriber === true) {
        return null
    }

    if (true) { return null } //TODO: voltar aqui

    return (
        <div
            className={`top-promo ${open ? "open" : ""}`}
            style={{ background: bg, fontSize: isMobile ? '15px' : '' }}
        >
            {open && (
                <div className={`promo-content ${isMobile ? "d-flex flex-column" : "d-flex align-items-center justify-content-center"}`}>

                    <div className="col-md-5 text-center">
                        <img
                            src="https://guildbucket.blob.core.windows.net/assets/Bau_Guilda_da_Abobora.webp"
                            alt="Baú"
                            className={`promo-img ${isMobile ? 'mobile-image' : ''}`}
                        />
                    </div>
                    <div className={`col-md-4 top-promo-text ${isMobile ? 'text-center' : ''}`}>
                        <h3>
                            <strong>Apoie a Guilda</strong>
                        </h3>
                        <h5>Tenha a experiência completa e ajude a bater as metas de conteúdos!</h5>
                        <p>
                            Corra pois falta pouco para mais conteúdos serem lançados. Obtenha o{" "}
                            <strong>pacote "Mundo Aquático"!</strong>
                        </p>

                        <Countdown />


                    </div>
                    <div className="col-md-3 text-center">
                        <Link to="/signature" className="btn promo-button" style={{ background: "#fcff41", color: "black", fontWeight: "700" }}>
                            SAIBA MAIS
                        </Link>
                    </div>
                </div>
            )}

            {!open && (
                <div className="message">
                    <p className="top-promo-closed-text">
                        <Link to="/signature" className="top-promo-closed-text">Conteúdo exclusivo. Saiba mais</Link>
                    </p>
                </div>
            )}

            <div className={!open ? 'open-handle' : 'down-arrow'} onClick={handleToggle} style={{ background: bg }}>
            </div>
        </div>
    );
}

export default PromoHeader;