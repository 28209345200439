import React, { useState, createContext } from "react";
import { toast } from 'react-toastify';
import api from '../../src/services/api';

export const UserContext = createContext();

const UserProvider = ({ children }) => {

  const [userInfo, setUserInfo] = useState(
    { name: '', surname: '', email: '', document: '', countryCode: "55", areaCode: "", number: "" }
  );

  const [addressInfo, setAddressInfo] = useState(
    { street: "", number: '', zipCode: "", city: "", state: "", country: "BR" }
  );

  //PEGAR DADOS DO USUÁRIO

  const [user, setUser] = useState();

  const getMyAccount = async () => {

    try {
      const accountGetter = await api.get('customer/get-one');
      const data = accountGetter.data;

      setUser(data)

      setUserInfo({
        name: data?.name,
        surname: data?.surname,
        email: data?.email,
        document: data?.document || "",
        countryCode: data?.countryCode || "55",
        areaCode: data?.areaCode || "",
        number: data?.number || ""
      })
      setAddressInfo({
        street: data?.address?.street || "",
        number: data?.address?.number || "",
        zipCode: data?.address?.zipCode || "",
        city: data?.address?.city || "",
        state: data?.address?.state || "",
        country: data?.address?.country || "BR"
      })

    } catch (error) {
      toast.warn("Erro:" + error);

    }
  };

  const userConstants = {
    PLANO_INATIVO: user?.plan === "INATIVO",
    PLANO_BASICO: user?.plan === "PLANO_BASICO",
    PLANO_INTERMEDIARIO: user?.plan === "PLANO_INTERMEDIARIO",
    PLANO_MASTER: user?.plan === "PLANO_MASTER",
    DESCONTO_INTERMEDIARIO: 0.15,
    DESCONTO_MASTER: 0.5,
  }

  return (
    <UserContext.Provider value={{
      userInfo,
      setUserInfo,
      addressInfo,
      setAddressInfo,
      user,
      setUser,
      getMyAccount,
      userConstants
    }}>
      {children}
    </UserContext.Provider>
  )

};

export default UserProvider;