import React, { useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { SignatureContext } from "../../Contexts/signature-context";
import './signature.css';
import { KnowMoreModal } from "./knowMoreModal";

const Signature = () => {
    const { plans, setPlan, getPlans } = useContext(SignatureContext);

    const [showModal, setShowModal] = useState(false);
    const [subscribeType, setSubscribeType] = useState("member");

    const [yearlyOrMonthly, setYearlyOrMonthly] = useState("yearly");
    const [memberPrice, setMemberPrice] = useState(null);
    const [heroPrice, setHeroPrice] = useState(null);
    const [bountyHunterPrice, setBountyHunterPrice] = useState(null); // Adiciona o preço do Caçador de Recompensas
    const [info, setInfo] = useState(" | No Plano Anual");

    const [planos, setPlanos] = useState(null);

    useEffect(() => {
        if (!plans) {
            getPlans();
        }
    }, [plans, getPlans]);

    useEffect(() => {
        if (plans) {
            const updatedPlansMap = {};
            plans.forEach(plan => {
                updatedPlansMap[plan.name] = plan;
            });

            setPlanos({
                membroMensal: updatedPlansMap["Membro Mensal"],
                membroAnual: updatedPlansMap["Membro Anual"],
                cacadorRecompensasMensal: updatedPlansMap["Cacador de Recompensas Mensal"],
                cacadorRecompensasAnual: updatedPlansMap["Cacador de Recompensas Anual"],
                heroiMensal: updatedPlansMap["Heroi Mensal"],
                heroiAnual: updatedPlansMap["Heroi Anual"]
            });

            // Define os preços iniciais com base no plano anual
            setMemberPrice(formatPrice(updatedPlansMap["Membro Anual"]?.minimumPrice));
            setHeroPrice(formatPrice(updatedPlansMap["Heroi Anual"]?.minimumPrice));
            setBountyHunterPrice(formatPrice(updatedPlansMap["Cacador de Recompensas Anual"]?.minimumPrice)); // Preço inicial Caçador de Recompensas
        }
    }, [plans]);

    function formatPrice(price) {
        if (!price) return "0,00";
        const priceString = price.toString().padStart(3, '0');
        const integerPart = priceString.slice(0, -2);
        const decimalPart = priceString.slice(-2);
        return `${integerPart},${decimalPart}`;
    }

    const checkMonthYear = (type) => {
        const isYearly = type === "yearly";
        const month = document.querySelector('.monthly');
        const year = document.querySelector('.yearly');

        month.classList.toggle('active', !isYearly);
        year.classList.toggle('active', isYearly);

        setYearlyOrMonthly(isYearly ? "yearly" : "monthly");

        // Atualiza os preços e informações para o tipo selecionado
        setMemberPrice(formatPrice(isYearly ? planos.membroAnual?.minimumPrice : planos.membroMensal?.minimumPrice));
        setHeroPrice(formatPrice(isYearly ? planos.heroiAnual?.minimumPrice : planos.heroiMensal?.minimumPrice));
        setBountyHunterPrice(formatPrice(isYearly ? planos.cacadorRecompensasAnual?.minimumPrice : planos.cacadorRecompensasMensal?.minimumPrice));
        setInfo(isYearly ? " | No Plano Anual" : "");
    };

    return (
        <>
            <section className="section-pricing ">
                <div className="container header-padding">
                    <div className="row align-items-center">
                        <div className="col-lg-5">
                            <div className="header-section">
                                <div className="plans-buttons">
                                    <span className="yearly active" onClick={() => checkMonthYear("yearly")}>Anual<span className="perc">-35%</span></span>
                                    <span className="monthly" onClick={() => checkMonthYear("monthly")}>Mensal</span>
                                </div>
                                <h2 className="title">Apoie a Guilda</h2>
                                <p className="description">
                                    Faça parte de uma comunidade de RPG de mesa super engajada.
                                    São <strong>novos monstros todos os meses</strong> para os membros, além de <strong>metas extras</strong> com
                                    cargos no Discord e <strong>direito a voto nas novas produções e funcionalidades do portal da Guilda.</strong>
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-7">
                            <div className="plans d-flex align-items-center">

                                <div className="single-plan startup text-center">
                                    <div className="content">
                                        <h3 className="title">Membros</h3>
                                        <p className="description">Desbloqueie conteúdos</p>
                                        <div className="know-more-button" onClick={() => { setSubscribeType("member"); setShowModal(true); }}>
                                            <p>{`Saber mais >`}</p>
                                        </div>
                                        <div className="icon">
                                            <img src={require('../../assets/emblemas_membro.png')} alt="Membro Emblema" />
                                        </div>
                                        <div className="price">
                                            <span className="dolar">R$</span>
                                            <span className="number">{memberPrice || ' ...'}</span>
                                            <span className="plan-name">Por Mês{info}</span>
                                        </div>

                                        <Link to='/signature-checkout' className="purchase-button" onClick={() => setPlan({ ...planos?.membroAnual, image: "../../assets/emblemas_membro.png" })}>
                                            Apoiar Agora
                                        </Link>
                                    </div>
                                </div>

                                <div className="single-plan startup text-center">
                                    <div className="content">
                                        <h3 className="title">Caçador de Recompensas</h3>
                                        <p className="description">Desbloqueie conteúdos e mais...</p>
                                        <div className="know-more-button" onClick={() => { setSubscribeType("bountyHunter"); setShowModal(true); }}>
                                            <p>{`Saber mais >`}</p>
                                        </div>
                                        <div className="icon">
                                            <img src={require('../../assets/emblemas_membro.png')} alt="Caçador Emblema" />
                                        </div>
                                        <div className="price">
                                            <span className="dolar">R$</span>
                                            <span className="number">{bountyHunterPrice || ' ...'}</span>
                                            <span className="plan-name">Por Mês{info}</span>
                                        </div>

                                        <Link to='/signature-checkout' className="purchase-button" onClick={() => setPlan({ ...planos?.cacadorRecompensasAnual, image: "../../assets/emblemas_bounty_hunter.png" })}>
                                            Apoiar Agora
                                        </Link>
                                    </div>
                                </div>

                                <div className="single-plan agency active text-center">
                                    <div className="content">
                                        <h3 className="title">Heróis e Heroínas</h3>
                                        <p className="description">Desbloqueie conteúdos e muito mais...</p>
                                        <div className="know-more-button hero-plan-destaq" onClick={() => { setSubscribeType("hero"); setShowModal(true); }}>
                                            <p>{`Saber mais >`}</p>
                                        </div>
                                        <div className="icon">
                                            <img src={require('../../assets/emblemas_hero.png')} alt="Herói Emblema" />
                                        </div>
                                        <div className="price">
                                            <span className="dolar">R$</span>
                                            <span className="number">{heroPrice || ' ...'}</span>
                                            <span className="plan-name">Por Mês{info}</span>
                                        </div>

                                        <Link to='/signature-checkout' className="purchase-button" onClick={() => setPlan({ ...planos?.heroiAnual, image: "../../assets/emblemas_hero.png" })}>
                                            Apoiar Agora
                                        </Link>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <KnowMoreModal showModal={showModal} setShowModal={setShowModal} subscribeType={subscribeType} />
        </>
    );
}

export default Signature;
