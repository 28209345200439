import React, { useEffect, useContext } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { UserContext } from "../Contexts/user-context";

const Authmiddleware = (props) => {
    const location = useLocation();
    const { user, userConstants } = useContext(UserContext);
    const token = localStorage.getItem('@user');

    const {PLANO_INATIVO } = userConstants

    if (!token) {
        return <Navigate to={{ pathname: "/login", state: { from: location } }} />;
    }

    // if (token && user && !user.emailCheck) {
    //     return <Navigate to={{ pathname: "/email-check", state: { from: location } }} />;
    // }

    if (token && user && (!user?.plan || PLANO_INATIVO)) {
        return <Navigate to={{ pathname: "/signature", state: { from: location } }} />;
    }

    return (
        <React.Fragment>
            {props.children}
        </React.Fragment>
    );
};

export default Authmiddleware;
