import React, { useState, useEffect, useContext } from "react";
import { UserContext } from "../../Contexts/user-context";
import './race-section.css'
import { Link } from "react-router-dom";
import { RaceContext } from "../../Contexts/race-context";

export default () => {

    const { races, getRaces, setSelectedRace } = useContext(RaceContext);
    const { user } = useContext(UserContext);

    const [raceList, setRaceList] = useState()

    useEffect(() => {
        getRaces()
    }, [user])

    useEffect(() => {

        if (races) {

            const sortedRaceList = [...races].sort((a, b) => {
                if (a.createdAt && b.createdAt) {
                    // Ambos têm a propriedade createdAt
                    return new Date(b.createdAt) - new Date(a.createdAt);
                } else if (a.createdAt) {
                    // Somente 'a' tem a propriedade createdAt
                    return -1; // Coloque 'a' antes de 'b'
                } else if (b.createdAt) {
                    // Somente 'b' tem a propriedade createdAt
                    return 1; // Coloque 'b' antes de 'a'
                } else {
                    // Nenhum dos dois tem a propriedade createdAt
                    return a.name.localeCompare(b.name);
                }
            });

            setRaceList(sortedRaceList);
            return
        }
        setRaceList(
            races?.sort((a, b) => {
                if (a.createdAt && b.createdAt) {
                    // Ambos têm a propriedade createdAt
                    return new Date(b.createdAt) - new Date(a.createdAt);
                } else if (a.createdAt) {
                    // Somente 'a' tem a propriedade createdAt
                    return -1; // Coloque 'a' antes de 'b'
                } else if (b.createdAt) {
                    // Somente 'b' tem a propriedade createdAt
                    return 1; // Coloque 'b' antes de 'a'
                } else {
                    // Nenhum dos dois tem a propriedade createdAt
                    return a.name.localeCompare(b.name);
                }
            })
        );
    }, [races])


    const [scrollX, setScrollX] = useState(0);
    const [newScreen, setNewScreen] = useState(220);

    const theSize = () => {
        let screenSize = window.screen.width;
        let widthList;
        if (screenSize < 760) { widthList = 170 } else { widthList = 260 }
        setNewScreen(widthList);
    }

    useEffect(() => {
        theSize();
        return
    }, []);

    const handleLeftArrow = () => {
        let x = scrollX + Math.round(window.innerWidth / 2);
        if (x > 0) { x = 0; } setScrollX(x);
    }
    const handleRigthArrow = () => {
        let x = scrollX - Math.round(window.innerWidth / 2);
        let listW = raceList.length * newScreen;
        if ((window.innerWidth - listW) > x) { x = (window.innerWidth - listW) - 30 }
        setScrollX(x)
    }

    const isRaceNew = (createdAt) => {
        if (!createdAt) {
            return false;
        }

        const oneMonthAgo = new Date();
        oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);

        const raceCreatedAt = new Date(createdAt);

        return raceCreatedAt > oneMonthAgo;
    };

    return (
        <>
            {true &&
                <div className="productRow">

                    <div className="session-text relative justify-content-between">
                        <div className="col-mb-3">
                            <h1>{`Raças de Fallgard ( ${raceList?.length} )`}</h1>
                            <div className="session-text-span">Jogue com personagens épicos ...</div>
                        </div>
                        {/* <Link to="races">Todo Conteúdo<i className="icon-angle-right ml-3"></i></Link> */}
                    </div>

                    <div className="productRow--left" onClick={handleLeftArrow}> ‹ </div>
                    <div className="productRow--right" onClick={handleRigthArrow}> › </div>
                    <div className="productRow--listarea">
                        <div className="productRow--list" onLoad={theSize}
                            style={
                                {
                                    marginLeft: scrollX,
                                    width: raceList && raceList.length * newScreen
                                }
                            }>
                            <div className="card-race-body">
                                {raceList && raceList?.map((race, index) => (
                                    <Link to={race?.slug ? `/races/${race?.slug}` : "/signature"} className="race-link"
                                        key={race._id}
                                        onClick={() => {
                                            setSelectedRace(race)
                                        }}>
                                        <div className="card-race">
                                            <div className="card-race-wrapper">
                                                {isRaceNew(race?.createdAt) && <span className="new">New</span>}
                                                <img src={race?.cover_image ? race.cover_image : "https://pumpkinstudio.com.br/wp-content/uploads/2023/09/Hover-Background-12.jpg"} className="cover-image" />
                                                <img src={race.hover} className="character-unhover" />
                                            </div>
                                            <h2 className="title">{race.title_hover}</h2>
                                            <img src={race.hover} className="character" />
                                        </div>
                                    </Link>
                                ))}
                            </div>

                        </div>
                    </div>
                </div>
            }
        </>
    );
}

