import React, { useRef, useEffect } from "react";

export const KnowMoreModal = ({ showModal, setShowModal, subscribeType }) => {
    const modalRef = useRef();

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                setShowModal(false);
            }
        };

        if (showModal) {
            document.addEventListener("click", handleClickOutside);
        }

        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, [showModal, setShowModal]);

    let info = {}

    switch (subscribeType) {
        case "member":
            info = {
                name: "Membro",
                infos: (
                    <ul className="description">
                        <li>Campanha anual com aventuras trimestrais</li>
                        <li>Novos materiais todos os meses (monstros, itens, mapas, npc, etc)</li>
                        <li>Cargo de Membro no Discord</li>
                    </ul>
                )
            };
            break;

        case "bountyHunter":
            info = {
                name: "Caçador de Recompensas",
                infos: (
                    <ul className="description">
                        <li>Campanha anual com aventuras trimestrais</li>
                        <li>Novos materiais todos os meses (monstros, itens, mapas, npc, etc)</li>
                        <li>Cargo de Caçador de Recompensas no Discord</li>
                        <li>15% de desconto nas aventuras antigas</li>
                        <li>Direito de Voto em Funcionalidades do App, Metas Extra e Novos Projetos</li>
                        <li>Nome nos Créditos das Criações</li>
                        <li>Acesso antecipado às novas funcionalidades do Portal</li>
                    </ul>
                )
            };
            break;

        case "hero":
            info = {
                name: "Herói | Heroína",
                infos: (
                    <ul className="description">
                        <li>Campanha anual com aventuras trimestrais</li>
                        <li>Novos materiais todos os meses (monstros, itens, mapas, npc, etc)</li>
                        <li>Cargo de Herói | Heroína no Discord</li>
                        <li>Acesso às Salas Especiais no Discord</li>
                        <li>50% de desconto nas aventuras antigas</li>
                        <li>Seu Voto vale 3x em Funcionalidades do App, Metas Extra e Novos Projetos</li>
                        <li>Nome nos Créditos das Criações como Herói | Heroína da Guilda</li>
                        <li>Acesso antecipado às novas funcionalidades do Portal</li>
                        <li>Colabore diretamente na criação de NPCs, monstros ou itens que serão incorporados ao conteúdo oficial da Guilda pelo Discord</li>
                    </ul>
                )
            };
            break;

        default:
            info = {
                name: "Viajante",
                infos: (
                    <ul className="description">
                        <li>Acesso básico ao Discord.</li>
                    </ul>
                )
            };
            break;
    }

    return (
        <div className={`modal fade ${showModal ? 'show' : ''}`} tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden={!showModal}>
            <div className="modal-dialog" role="document">
                <div ref={modalRef} className="modal-content cancel-modal">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">{info?.name}</h5>
                        <button type="button" className="close" onClick={() => setShowModal(false)} aria-label="Fechar">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        {info?.infos}
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-success" onClick={() => setShowModal(false)} style={{ color: 'white' }}>Ok</button>
                    </div>
                </div>
            </div>
        </div>
    );
};
