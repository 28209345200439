import React, { useState, useEffect, useContext } from "react";
import Aventuras from '../Aventuras/Aventuras'
import './Products.css';

import { ProductContext } from "../../Contexts/product-context";
import { Link } from "react-router-dom";


export default () => {

    const { collection, manuals, getManuals } = useContext(ProductContext);

    const arrayMenor = collection;
    const arrayMaior = manuals;

    const resultado = arrayMaior.filter(
        itemMaior => !arrayMenor.some(itemMenor => itemMenor.id === itemMaior.id)
    ) || [];

    const [scrollX, setScrollX] = useState(0);
    const [newScreen, setNewScreen] = useState(220);

    const theSize = () => {
        let screenSize = window.screen.width;
        let widthList;
        if (screenSize < 760) { widthList = 170 } else { widthList = 270 }
        setNewScreen(widthList);
    }

    useEffect(() => {
        theSize();
        return
    }, []);

    useEffect(() => {
        getManuals()
    }, [])

    const handleLeftArrow = () => {
        let x = scrollX + Math.round(window.innerWidth / 2);
        if (x > 0) { x = 0; } setScrollX(x);
    }
    const handleRigthArrow = () => {
        let x = scrollX - Math.round(window.innerWidth / 2);
        let listW = resultado.length * newScreen;
        if ((window.innerWidth - listW) > x) { x = (window.innerWidth - listW) - 30 }
        setScrollX(x)
    }

    return (

        <>
            {resultado.length != 0 &&
                <div className="productRow">

                    <div className="session-text relative justify-content-between">
                        <div className="col-mb-3">
                            <h1>Manuais ( {resultado.length} )</h1>
                            <div className="session-text-span">Torne seu jogo mais impactante com esses manuais incríveis...</div>
                        </div>
                        {/* <Link to="">Todo Conteúdo<i className="icon-angle-right ml-3"></i></Link> */}
                    </div>

                    <div className="productRow--left" onClick={handleLeftArrow}> ‹ </div>
                    <div className="productRow--right" onClick={handleRigthArrow}> › </div>

                    <div className="productRow--listarea">
                        <div className="productRow--list" onLoad={theSize}
                            style={
                                {
                                    marginLeft: scrollX,
                                    width: resultado.length * newScreen
                                }
                            }>

                            <div >

                                {resultado.length > 0 &&

                                    resultado.map((aventura, key) => (
                                        <Aventuras aventura={aventura} key={key} />
                                    ))}
                            </div>

                        </div>
                    </div>
                </div>}
        </>
    );
}

