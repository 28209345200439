import React, { useContext, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import api from '../../services/api';
import './Login.css';
import { SignatureContext } from '../../Contexts/signature-context';

function Login() {

  const { plan } = useContext(SignatureContext);

  const [credenciais, setCredenciais] = useState({ email: '', password: '' });
  const [isLoading, setIsLoading] = useState(false)

  const navigate = useNavigate();


  const login = async () => {

    window.addEventListener('submit', function (e) { e.preventDefault() })

    const validateFields = document.querySelectorAll('#frm-signin :invalid').length;
    if (validateFields !== 0) { return false }

    setIsLoading(true)

    await api.post('customer/authenticate', credenciais)
      .then(response => {

        let getToken = JSON.stringify(response.headers.authorization);
        //let getRefreshToken = JSON.stringify(response.headers["refresh-token"]);
        //let getUserInfo = JSON.stringify(response.data.data);
        localStorage.setItem('@user', JSON.parse(getToken));
        //localStorage.setItem('@refreshToken', JSON.parse(getRefreshToken));
        //localStorage.setItem('@userInfo', getUserInfo);

        if (plan) {
          navigate('/signature-checkout')
        }

        else {
          navigate('/')
        }
      })
      .catch((reason) => {
        setIsLoading(false)
        if (reason?.response?.status === 400) {
          toast.warn(reason.response?.data?.errors[0]?.Message);
        } else if (reason?.response?.status === 404) {
          toast.warn('E-mail ou senha inválidos!');
        } else {
          toast.warn(reason?.message);
        }
      });
  };

  return (

    <div className="container-login-page">
      <div className="frm-main-container">
        <div className="inner">
          <div className="frm-change-links">
            <span onClick={() => { navigate('/login') }} className="active" id="signin-btn">ENTRAR</span>
            <span onClick={() => { navigate('/signup') }} id="signup-btn">REGISTRAR</span>
          </div>

          {/* Login */}

          <form action="" className="frm-signin" id="frm-signin">
            <div className="frm-group">
              <label htmlFor="email">E-mail</label>
              <input onChange={(e) => {
                setCredenciais({ ...credenciais, email: e.target.value.trim() });
              }}
                type="email"
                className="form-control form-control-lg"
                id='login_email'
                placeholder="E-mail"
                required
                autoComplete="on"
              />
            </div>
            <div className="frm-group" id="toLogPass">
              <label htmlFor="password">Senha</label>
              <input onChange={(e) => {
                setCredenciais({ ...credenciais, password: e.target.value });
              }}
                type="password"
                className="form-control form-control-lg"
                id='login_password'
                placeholder="Senha"
                pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                title="Sua senha contém no mínimo 8 caracteres e pelo menos uma letra maiúscula, uma minúscula e um número."
                size="36"
                required
                autoComplete="off"
              />
              <span type="button" className="btn-show-pass" id='logIn_pass' onClick={(event) => {
                const passInput = document.getElementById('login_password')
                const passEye = document.querySelector('#toLogPass .fa')
                if (passInput.type === 'password') {
                  passEye.classList.remove('fa-eye-slash')
                  passInput.type = 'text'
                }
                else {
                  passEye.classList.add('fa-eye-slash')
                  passInput.type = 'password'
                }
              }}>
                <i id="eyeIcon" className="fa fa-eye fa-eye-slash"></i>
              </span>

            </div>
            <div className="frm-group chk">
              <input type="checkbox" id="kmsi" />
              <label htmlFor="kmsi">Mantenha-me Conectado</label>
            </div>
            <div className="frm-group">

              {!isLoading && <button onClick={login} className="login_btn" id="login_btn" type="submit"> ENTRAR </button>}
              {isLoading && <button disabled className="loadingBtn" type="submit" >{'ENTRANDO '}</button>}

            </div>
            <hr />
            <div className="text-center">
              <Link to="/forgot-password">
                <span className="fp-link">Esqueceu a Senha?</span>
              </Link>
            </div>
          </form>

        </div>
      </div>
    </div>
  );
}

export default Login;
