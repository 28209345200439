import React, { useState, useEffect, useContext } from "react";
import { MonsterContext } from "../../Contexts/monster-context";
import { ProductContext } from "../../Contexts/product-context";
import { UserContext } from "../../Contexts/user-context";
import './monster-section.css'
import { Link } from "react-router-dom";

export default () => {

    const { monsters, getMonsters, setSelectedMonster } = useContext(MonsterContext);
    const { user } = useContext(UserContext);

    const [monsterList, setMonsterList] = useState()
    const [filteredString, setFilteredString] = useState('')

    useEffect(() => {
        getMonsters()
    }, [user])

    useEffect(() => {

        if (monsters) {

            const sortedMonsterList = [...monsters].sort((a, b) => {
                if (a.createdAt && b.createdAt) {
                    // Ambos têm a propriedade createdAt
                    return new Date(b.createdAt) - new Date(a.createdAt);
                } else if (a.createdAt) {
                    // Somente 'a' tem a propriedade createdAt
                    return -1; // Coloque 'a' antes de 'b'
                } else if (b.createdAt) {
                    // Somente 'b' tem a propriedade createdAt
                    return 1; // Coloque 'b' antes de 'a'
                } else {
                    // Nenhum dos dois tem a propriedade createdAt
                    return a.name.localeCompare(b.name);
                }
            });

            setMonsterList(sortedMonsterList);
            return
        }
        setMonsterList(
            monsters?.sort((a, b) => {
                if (a.createdAt && b.createdAt) {
                    // Ambos têm a propriedade createdAt
                    return new Date(b.createdAt) - new Date(a.createdAt);
                } else if (a.createdAt) {
                    // Somente 'a' tem a propriedade createdAt
                    return -1; // Coloque 'a' antes de 'b'
                } else if (b.createdAt) {
                    // Somente 'b' tem a propriedade createdAt
                    return 1; // Coloque 'b' antes de 'a'
                } else {
                    // Nenhum dos dois tem a propriedade createdAt
                    return a.name.localeCompare(b.name);
                }
            })
        );
    }, [monsters])


    const [scrollX, setScrollX] = useState(0);
    const [newScreen, setNewScreen] = useState(220);

    const theSize = () => {
        let screenSize = window.screen.width;
        let widthList;
        if (screenSize < 760) { widthList = 170 } else { widthList = 260 }
        setNewScreen(widthList);
    }

    useEffect(() => {
        theSize();
        return
    }, []);

    const handleLeftArrow = () => {
        let x = scrollX + Math.round(window.innerWidth / 2);
        if (x > 0) { x = 0; } setScrollX(x);
    }
    const handleRigthArrow = () => {
        let x = scrollX - Math.round(window.innerWidth / 2);
        let listW = monsterList.length * newScreen;
        if ((window.innerWidth - listW) > x) { x = (window.innerWidth - listW) - 30 }
        setScrollX(x)
    }

    const isMonsterNew = (createdAt) => {
        if (!createdAt) {
          return false;
        }
      
        const oneMonthAgo = new Date();
        oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);
      
        const monsterCreatedAt = new Date(createdAt);
        
        return monsterCreatedAt > oneMonthAgo;
      };

    return (
        <>
            {true &&
                <div className="productRow">

                    <div className="session-text relative justify-content-between">
                        <div className="col-mb-3">
                            <h1>{`Bestiário da Guilda ( ${monsterList?.length} )`}</h1>
                            <div className="session-text-span">Confira os monstros disponíveis ...</div>
                        </div>
                        {/* <Link to="monsters">Todo Conteúdo<i className="icon-angle-right ml-3"></i></Link> */}
                    </div>

                    <div className="productRow--left" onClick={handleLeftArrow}> ‹ </div>
                    <div className="productRow--right" onClick={handleRigthArrow}> › </div>
                    <div className="productRow--listarea">
                        <div className="productRow--list" onLoad={theSize}
                            style={
                                {
                                    marginLeft: scrollX,
                                    width: monsterList && monsterList.length * newScreen
                                }
                            }>
                            <div className="card-monster-body">
                                {monsterList && monsterList.map((monster, index) => (
                                    <Link to={monster?.slug ? `/monster/${monster?.slug}` : "/signature"} className="monster-link"
                                        key={monster._id}
                                        onClick={() => {
                                            setSelectedMonster(monster)
                                        }}>
                                        <div className="card-monster">
                                            <div className="card-monster-wrapper">
                                            {isMonsterNew(monster?.createdAt) && <span className="new">New</span>}
                                            <img src={monster?.cover_image ? monster.cover_image : "https://pumpkinstudio.com.br/wp-content/uploads/2023/09/Hover-Background-12.jpg"} className="cover-image" />
                                                <img src={monster.hover} className="character-unhover" />
                                            </div>
                                            <h2 className="title">{monster.titleHover}</h2>
                                            <img src={monster.hover} className="character" />
                                        </div>
                                    </Link>
                                ))}
                            </div>

                        </div>
                    </div>
                </div>
            }
        </>
    );
}

