import React, { useState, createContext } from "react";
import { toast } from 'react-toastify';
import api from '../../src/services/api';

export const CartContext = createContext();

const CartProvider = ({ children }) => {

    const [cart, setCart] = useState(null);

    const getCart = async () => {
        try {
            const response = await api.get('cart');
            const res = response.data;

            if (res.cart === null) { return }

            setCart(res.cart); // CARREGA O CARRINHO DO USUÁRIO CONFORME O TOKEN       

        } catch (err) {
            toast.warn("Não há um carrinho associado ao usuário" + err.message);
            localStorage.clear();
        }
    };

    const addToCart = async (product) => {

        const chosedItem = { items: [{ productId: `${product.id}` }] };

        try {
            await api.put(`cart/add-item`, chosedItem);
            toast.success(`${product.title} foi adicionado(a) ao carrinho`);
            await getCart();
        } catch (err) {
            toast.warn("Erro ao processar requisição: " + err.message);
        }
    };

    const removeCartItem = async (item) => {
        const chosedItem = { items: [{ productId: `${item}` }] };
        try {
            const response = await api.put(`cart/remove-item`, chosedItem);
            const res = response.data;

            if (res.error) {
                console.error(res.message);
                return false;
            }
            getCart()
        } catch (err) {
            console.error(err.message);
        }
    }

    return (
        <CartContext.Provider value={{ cart, getCart, addToCart, removeCartItem }}>
            {children}
        </CartContext.Provider>
    )
};

export default CartProvider;